import {
  Button,
  ErrorPage,
  FullPageLoader,
  Margin,
  useOnMount,
} from '@kivra/react-components';
import type { HeimdallToken } from '@kivra/sdk/authentication';
import type { Session } from '@kivra/sdk/identity';
import { userSession } from '@kivra/sdk/identity';
import { createHeimdallAuthentication } from '@sender-portal-fe/util-shared/src/sdk/authentication/heimdall';
import React, { useState } from 'react';
import { useConfig, useCopy, useHeimdallParameters } from '../globalContext';
import { getOnLoginRoute, history } from '../routes/history';
import { ROUTE_START } from '../routes/Router';

const login = (token: HeimdallToken, onLoginRoute?: string): void => {
  const session: Session = {
    accessToken: token.accessToken,
    userId: token.idToken,
    userType: 'non_user',
    expiryTime: Math.floor(
      (new Date().getTime() + token.expiresIn * 1000) / 1000
    ),
  };
  userSession.setSession(session);
  history.push(onLoginRoute ?? ROUTE_START);
};

export const LoginPage = (): React.JSX.Element => {
  const { authorizationCode, redirectState, error } = useHeimdallParameters();
  const [hasError, setHasError] = useState(Boolean(error));
  const config = useConfig();
  const getCopy = useCopy();

  const heimdallAuthentication = createHeimdallAuthentication<{
    route: string;
  }>({
    clientId: config.sender_backoffice_heimdall_client_id,
    heimdallUrl: config.sender_api_origin,
    redirectUrl: config.sender_backoffice_origin,
  });
  const onLoginRoute = getOnLoginRoute();

  useOnMount(async () => {
    if (authorizationCode && redirectState) {
      const { token, applicationState, error } =
        await heimdallAuthentication.getToken(authorizationCode, redirectState);

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (error || !token || !applicationState) {
        setHasError(true);
      } else {
        login(token, applicationState.route);
      }
    } else if (!hasError) {
      void heimdallAuthentication.initiateLogin({
        route: onLoginRoute || ROUTE_START,
      });
    }
  });

  if (hasError) {
    return (
      <ErrorPage title={getCopy('sender_backoffice__error_login_title')}>
        <Margin bottom={16}>
          {getCopy('sender_backoffice__error_login_body')}
        </Margin>
        <Button onClick={() => location.reload()}>
          {getCopy('btn__try_again')}
        </Button>
      </ErrorPage>
    );
  }

  return <FullPageLoader />;
};
